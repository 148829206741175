import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorHandler, Product } from "../../shared/types";
import { AxiosError } from "axios";
import ProductService from "../../services/ProductService";

const productService = new ProductService();

// eslint-disable-next-line import/prefer-default-export
export const fetchProduct = createAsyncThunk<Product, { id: string }>(
  "product/fetchProduct",
  async (params, thunkApi) => {
    try {
      const result = await productService.getProductById(params.id);
      return result;
    } catch (error) {
      const err = error as AxiosError<ErrorHandler | string>;
      return thunkApi.rejectWithValue({
        code: err.response.status,
        error: err?.response?.data,
      });
    }
  }
);
