import { lazy } from "react";
import { sleep } from "./sleep";

export interface DelayedImportOptions {
  fallbackTime: number;
  animationTime: number;
}

export type DelayedImportResult = ReturnType<Parameters<typeof lazy>[0]>;

/**
 * Delays an import when this take longer that the fallback time,
 * the time will be the specified fallback time + the animation time.
 *
 * ex: load a component with suspense and show loader only
 * when takes more than 50ms and keep loader for 800ms to avoid flashing
 *
 * @example
 * export const ROUTE_CONFIG: DelayedImportOptions = { fallbackTime: 50, animationTime: 800 }
 *
 * const Home = lazy(() => delayedImport('home', ROUTE_CONFIG))
 *
 * const App = () => (
 *   <ErrorBoundary>
 *     <BrowserRouter>
 *       <Switch>
 *         <Suspense fallback={<DelayedLoader delay={ROUTE_CONFIG.fallbackTime} />}>
 *           <Route exact path="/" component={Home} />
 *         </Suspense>
 *       </Switch>
 *     </BrowserRouter>
 *   </ErrorBoundary>
 * )
 *
 */
export async function delayedImport(
  url: string,
  { fallbackTime, animationTime }: DelayedImportOptions
): DelayedImportResult {
  // this assume components are under src there is a problem with webpack that can't read url as a variable directly
  const module = await Promise.race([
    import(`/src/${url}`),
    sleep(fallbackTime),
  ]);

  if (module) {
    return module;
  }

  await sleep(animationTime);

  return import(`/src/${url}`);
}
