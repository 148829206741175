import authApi from "../shared/api/auth";
import customerApi from "../shared/api/customer";
import { Application } from "../shared/types";
import { EmailVerificationResponse, User } from "../types/user";

export default class UserService {
  private authPath = "/auth";

  private userPath = "/users";

  async verifyEmail(params: {
    email: string;
    uniqueId: string;
    productName: string;
    language: string;
  }): Promise<string> {
    const response = await authApi.post(
      `${this.authPath}/verify-email`,
      params
    );
    return response.data;
  }

  async verifyLink(params: {
    token: string;
    uniqueId: string;
  }): Promise<EmailVerificationResponse> {
    const response = await authApi.post(`${this.authPath}/verify-link`, params);
    return response.data;
  }

  async updateCustomer(user: User, orderId?: string): Promise<User> {
    const { _id, ...userData } = user;
    const response = await customerApi.put(
      `${this.userPath}/${_id}`,
      userData,
      {
        params: { orderId, application: Application.HOMESAMPLING },
      }
    );
    return response.data;
  }

  async updatePartialUser(params: {
    field: string;
    value: string | boolean | Date;
    id: string;
  }): Promise<User> {
    const { field, value, id } = params;
    const response = await customerApi.put<User>(
      `${this.userPath}/${id}`,
      {
        [field]: value,
      },
      { params: { application: Application.HOMESAMPLING } }
    );
    return response.data;
  }
}
