import { createAsyncThunk } from "@reduxjs/toolkit";
import { Appointment, ErrorHandler } from "../../shared/types";
import { AxiosError } from "axios";
import AppointmentService from "../../services/AppointmentService";

const appointmentService = new AppointmentService();

// eslint-disable-next-line import/prefer-default-export
export const rescheduleAppointment = createAsyncThunk<
  Appointment,
  {
    _id: string;
    orderId: string;
    partnerBranchId: string;
    scheduleFrom: Date;
    scheduleTo: Date;
    userId: string;
    status?: number;
    crmAdmin?: boolean;
  }
>("/apointments/rescheduleAppointment", async (params, thunkApi) => {
  try {
    const result = await appointmentService.updateAppointment(params);
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});
