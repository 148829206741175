import api from "../shared/api/homesampling";
import { Application, Order } from "../shared/types";
import { RequestCreateOrder } from "../types/order";

export default class OrderService {
  private path = "/orders";

  async createNewOrder(
    data: RequestCreateOrder,
    language: string
  ): Promise<Order> {
    const response = await api.post<Order>(this.path, data, {
      params: { application: Application.HOMESAMPLING, language },
    });
    return response.data;
  }

  async getOrder(uniqueId: string, email: string): Promise<Order> {
    const response = await api.get<Order>(`${this.path}/${uniqueId}`, {
      params: { uniqueId, email },
    });
    return response.data;
  }
}
