import { AxiosError } from 'axios'
import { UnaryFunction } from '../types/unary-function.type'

const WHITE_LISTED_URLS = [
  '/auth/verify',
  '/auth/signup-verify',
  '/auth/user',
  '/auth/refresh-token',
]

export interface CreateErrorInterceptorParams {
  redirectPath: string
  redirect: UnaryFunction<string, void>
  whitelistedUrls?: string[]
}

/**
 * Creates error interceptor that redirects to desired `redirectPath` when
 * request is unauthorized and is not one of the whitelisted routes
 * @param params -
 * - redirectPath: path to be redirected in case request is unauthorized
 * - redirect: function that redirects in case request is unauthorized
 * - whitelistedUrls: list of urls that should not be redirected even when request is unauthorized
 * @example
 *
 * const axiosInstance = axios.create({
 *   baseURL: 'http://example.com',
 * })
 *
 * const errorInterceptor = createErrorInterceptor({
 *   redirectPath: AuthPaths.AUTH_LOGIN,
 *   whitelistedUrls: ['/login'],
 * })
 *
 * axiosInstance.interceptors.response.use(identity, errorInterceptor)
 *
 */
export function createErrorInterceptor({
  redirectPath,
  redirect,
  whitelistedUrls = WHITE_LISTED_URLS,
}: CreateErrorInterceptorParams): UnaryFunction<any, void> {
  return (error: AxiosError) => {
    const { url } = error.config
    const { status } = error.response

    if (status === 401 && whitelistedUrls.every((wlUrl) => wlUrl !== url)) {
      redirect(redirectPath)
    }

    throw error
  }
}
