import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorHandler } from "../../shared/types";
import { AxiosError } from "axios";
import UserService from "../../services/UserService";
import { IErrorComponent } from "../../types";
import { EmailVerificationResponse, User } from "../../types/user";

const userService = new UserService();

// eslint-disable-next-line import/prefer-default-export
export const verifyEmail = createAsyncThunk<
  string,
  {
    email: string;
    uniqueId: string;
    productName: string;
    language: string;
  }
>("user/verifyEmail", async (params, thunkApi) => {
  try {
    const result = await userService.verifyEmail(params);
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});

// eslint-disable-next-line import/prefer-default-export
export const verifyLink = createAsyncThunk<
  EmailVerificationResponse,
  {
    token: string;
    uniqueId: string;
  },
  {
    rejectValue: IErrorComponent;
  }
>("user/verifyLink", async (params, thunkApi) => {
  try {
    const result = await userService.verifyLink(params);
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});

// eslint-disable-next-line import/prefer-default-export
export const updateCustomer = createAsyncThunk<
  User,
  { user: User; orderId?: string }
>("user/updateCustomer", async (params, thunkApi) => {
  try {
    const result = await userService.updateCustomer(
      params.user,
      params.orderId
    );
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});

export const updateLanguage = createAsyncThunk<
  { preferredLanguage: string },
  { id: string; language: string }
>("user/updateLanguage", async (params, thunkApi) => {
  try {
    const { id, language } = params;
    const result = await userService.updatePartialUser({
      id,
      field: "preferredLanguage",
      value: language,
    });
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});
