import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { MeasureLevel } from '../types'

const config = {
  envName: process.env.REACT_APP_ENV_NAME || 'local',
  appName: 'Rikai',
  defaultLanguage: 'de',
  portal: {
    customer: process.env.REACT_APP_CUSTOMER_PORTAL_URL || 'http://localhost:3000',
    admin: process.env.REACT_APP_ADMIN_PORTAL_URL || 'http://localhost:3002',
    doctor: process.env.REACT_APP_DOCTOR_PORTAL_URL || 'http://localhost:3000/doctor-panel',
    partner: process.env.REACT_APP_PARTNER_PORTAL_URL
      ? `${process.env.REACT_APP_PARTNER_PORTAL_URL}/partner`
      : 'http://localhost:3005/partner',
  },
  api: {
    customer: process.env.REACT_APP_CUSTOMER_API_URI || 'http://localhost:4004/api',
    crm: process.env.REACT_APP_CRM_API_URI || 'http://localhost:4004/api',
    admin: process.env.REACT_APP_ADMIN_API_URI || 'http://localhost:4004/api',
    auth: process.env.REACT_APP_AUTH_API_URI || 'http://localhost:4004/api',
    voucher: process.env.REACT_APP_VOUCHER_API_URI || 'http://localhost:4004/api',
  },
  services: {
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    googleMapLibraries: ['places'] as Libraries,
    logDnaKey: process.env.REACT_APP_LOGDNA_KEY,
  },
  defaultMapCenter: {
    lat: 47.138702,
    lng: 9.165527,
  },
  phone: {
    defaultCountryCode: process.env.REACT_APP_PHONE_COUNTRY_CODE || '+41',
    prefixes: process.env.REACT_APP_PHONE_PREFIXES?.split(',') || [
      '079',
      '078',
      '077',
      '076',
      '075',
    ],
    defaultCountry: process.env.REACT_APP_PHONE_DEFAULT_COUNTRY || 'CH',
    customer: {
      countries: process.env.REACT_APP_PHONE_ALLOWED_CUSTOMER_COUNTRY_CODES?.split(',') || ['CH'],
    },
    partner: {
      countries: process.env.REACT_APP_PHONE_ALLOWED_PARTNER_COUNTRY_CODES?.split(',') || ['CH'],
    },
  },
  languageOptions: process.env.REACT_APP_LANGUAGE_OPTIONS?.split(',') || ['de', 'en', 'fr', 'it'],
  endDateYear: 7,
  timeSlotShow: 6,
  restrictedHours: 4,
  indicatorOptions: [
    MeasureLevel.UNJUDGEABLE,
    MeasureLevel.BAD,
    MeasureLevel.BELOW_AVERAGE,
    MeasureLevel.GOOD,
  ],
  conversionTrackingId: 'AW-10841042240',
  minAge: 18,
  maxAge: 120,
  rescheduleFeeEnableDate: '2023-02-01',
  maxHourForAppointmentBooking: 13,
  rikaiUrl: 'https://www.rikai.ch',
  termsAndConditionsUrls: {
    de: 'https://www.rikai.ch/agb',
    en: 'https://www.rikai.ch/en/general-terms-and-conditions/',
    fr: 'https://www.rikai.ch/fr/conditions-generales-de-vente',
  },
  dataPrivacyUrls: {
    de: 'https://www.rikai.ch/datenschutz/',
    en: 'https://www.rikai.ch/en/data-protection/ ',
    fr: 'https://www.rikai.ch/fr/protection-des-donnees/',
  },
  callBackBookingUrls: {
    de: 'https://rik.pt/callbackDE',
    fr: 'https://rik.pt/callbackFR',
    en: 'https://rik.pt/callbackEN',
    it: 'https://rik.pt/callbackIT',
  },
  homesamplingTermsAndConditionsUrl: 'https://www.homesampling.ch/agb/bvb',
  homesamplingDataPrivacyUrl: 'https://www.homesampling.ch/datenschutz',
  maxSMSLength: 500,
}

export default config
