/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatus, Appointment, TimeSlot } from "../../shared/types";
import { IErrorComponent } from "../../types";
import { AppointmentState } from "../../types/appointment";
import { EmailVerificationResponse } from "../../types/user";
import { verifyLink } from "../user/UserThunk";
import { rescheduleAppointment } from "./AppointmentThunk";

const initialState: AppointmentState = {
  currentTimeslot: null,
  currentAppointment: { apiStatus: ApiStatus.idle },
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    saveTimeslot: (
      state: AppointmentState,
      action: PayloadAction<TimeSlot>
    ) => {
      return { ...state, currentTimeslot: action.payload };
    },
    setCurrentAppointment: (
      state: AppointmentState,
      action: PayloadAction<Appointment>
    ) => {
      return {
        ...state,
        currentAppointment: {
          apiStatus: ApiStatus.fulfilled,
          data: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      rescheduleAppointment.pending,
      (state: AppointmentState) => {
        state.currentAppointment.apiStatus = ApiStatus.pending;
      }
    );
    builder.addCase(
      rescheduleAppointment.fulfilled,
      (state: AppointmentState, action: PayloadAction<Appointment>) => {
        state.currentAppointment = {
          apiStatus: ApiStatus.fulfilled,
          data: action.payload,
        };
      }
    );
    builder.addCase(
      rescheduleAppointment.rejected,
      (state: AppointmentState, action) => {
        state.currentAppointment = {
          apiStatus: ApiStatus.rejected,
          error: action.payload as IErrorComponent,
        };
      }
    );
    builder.addCase(
      verifyLink.fulfilled,
      (
        state: AppointmentState,
        action: PayloadAction<EmailVerificationResponse>
      ) => {
        return {
          ...state,
          currentAppointment: {
            ...state.currentAppointment,
            data: action.payload.order?.appointment,
          },
        };
      }
    );
  },
});

export const { saveTimeslot, setCurrentAppointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;
