import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatus } from "../../shared/types";
import {
  HomeSamplingCustomerSurvey,
  HomeSamplingSurveyQuestion,
  SurveyState,
} from "../../types/survey";
import { getSurvey, getSurveyQuestion, saveSurvey } from "./SurveyThunk";

const initialState: SurveyState = {
  currentSurvey: {
    apiStatus: ApiStatus.idle,
    data: null,
  },
  currentQuestion: {
    apiStatus: ApiStatus.idle,
    data: null,
  },
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurvey.pending, (state: SurveyState) => {
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      getSurvey.fulfilled,
      (
        state: SurveyState,
        action: PayloadAction<HomeSamplingCustomerSurvey>
      ) => {
        return {
          ...state,
          currentSurvey: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getSurvey.rejected, (state: SurveyState) => {
      return {
        ...state,
        currentSurvey: {
          apiStatus: ApiStatus.rejected,
          data: null,
        },
      };
    });
    builder.addCase(getSurveyQuestion.pending, (state: SurveyState) => {
      return {
        ...state,
        currentQuestion: {
          ...state.currentQuestion,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      getSurveyQuestion.fulfilled,
      (
        state: SurveyState,
        action: PayloadAction<HomeSamplingSurveyQuestion>
      ) => {
        return {
          ...state,
          currentQuestion: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getSurveyQuestion.rejected, (state: SurveyState) => {
      return {
        ...state,
        currentQuestion: {
          apiStatus: ApiStatus.rejected,
          data: null,
        },
      };
    });
    builder.addCase(saveSurvey.pending, (state: SurveyState) => {
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      saveSurvey.fulfilled,
      (
        state: SurveyState,
        action: PayloadAction<HomeSamplingCustomerSurvey>
      ) => {
        return {
          ...state,
          currentSurvey: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(saveSurvey.rejected, (state: SurveyState) => {
      return {
        ...state,
        currentSurvey: {
          apiStatus: ApiStatus.rejected,
          data: null,
        },
      };
    });
  },
});

export default surveySlice.reducer;
