import { configureStore } from '@reduxjs/toolkit'
import ProductReducer from './product/ProductReducer'
import UserReducer from './user/UserReducer'
import PartnerReducer from './partner/PartnerReducer'
import AppointmentReducer from './appointment/AppointmentReducer'
import OrderReducer from './order/OrderReducer'
import SurveyReducer from './survey/SurveyReducer'

const store = configureStore({
  reducer: {
    user: UserReducer,
    partner: PartnerReducer,
    product: ProductReducer,
    order: OrderReducer,
    appointment: AppointmentReducer,
    survey: SurveyReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})
export default store
