import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorHandler, MultiDaysTimeSlot } from "../../shared/types";
import { AxiosError } from "axios";
import PartnerService from "../../services/PartnerService";

const partnerService = new PartnerService();

// eslint-disable-next-line import/prefer-default-export
export const fetchTimeslots = createAsyncThunk<
  MultiDaysTimeSlot[],
  { productId: string; startDate: Date; appointmentId?: string }
>("partner/fetchTimeslots", async (params, thunkApi) => {
  try {
    const result = await partnerService.getAvailableTimeSlot(
      params.productId,
      params.startDate,
      params.appointmentId
    );
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});
