import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatus, Product } from "../../shared/types";
import { ProductState } from "../../types/product";
import { fetchProduct } from "./ProductThunk";

const initialState: ProductState = {
  currentProduct: {
    apiStatus: ApiStatus.idle,
    data: null,
  },
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.pending, (state: ProductState) => {
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      fetchProduct.fulfilled,
      (state: ProductState, action: PayloadAction<Product>) => {
        return {
          ...state,
          currentProduct: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(fetchProduct.rejected, (state: ProductState) => {
      return {
        ...state,
        currentProduct: {
          apiStatus: ApiStatus.rejected,
          data: null,
        },
      };
    });
  },
});

export default productSlice.reducer;
