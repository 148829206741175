import { AuthToken } from '../types'

export const getAuthToken = (): AuthToken => {
  try {
    const authToken = JSON.parse(sessionStorage.getItem('authToken'))

    if (!authToken.accessToken || !authToken.idToken) throw new Error('AuthToken is not valid')

    return authToken
  } catch (e) {
    return {
      accessToken: null,
      idToken: null,
      expiresIn: null,
    }
  }
}

export const setAuthToken = (authToken: AuthToken): boolean => {
  try {
    if (!authToken.accessToken || !authToken.idToken) throw new Error('AuthToken is not valid')
    sessionStorage.setItem('authToken', JSON.stringify(authToken))

    return true
  } catch (e) {
    return false
  }
}
