import { SurveyAnswerValue } from "../shared/types";
import api from "../shared/api/customer";
import {
  HomeSamplingCustomerSurvey,
  HomeSamplingSurveyQuestion,
} from "../types/survey";

export default class SurveyService {
  private surveysPath = "/customer-surveys";

  private questionsPath = "/survey-questions";

  async getSurveyById(id: string): Promise<HomeSamplingCustomerSurvey> {
    const response = await api.get(`${this.surveysPath}/${id}`);
    return response.data;
  }

  async getSurveyQuestion(id: string): Promise<HomeSamplingSurveyQuestion> {
    const response = await api.get(`${this.questionsPath}/${id}`);
    return response.data;
  }

  async saveSurvey(
    id: string,
    answers: Array<{
      questionId: string;
      answerValue: SurveyAnswerValue;
      questionKey?: string;
      additionalAnswer?: SurveyAnswerValue;
    }>,
    uniqueId?: string
  ): Promise<HomeSamplingCustomerSurvey> {
    const response = await api.put(
      `${this.surveysPath}/${id}/answers`,
      answers,
      {
        params: { uniqueId },
      }
    );
    return response.data;
  }
}
