import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorHandler, Order } from "../../shared/types";
import { AxiosError } from "axios";
import i18next from "i18next";
import OrderService from "../../services/OrderService";
import { RequestCreateOrder } from "../../types/order";

const orderService = new OrderService();

// eslint-disable-next-line import/prefer-default-export
export const createOrder = createAsyncThunk<Order, RequestCreateOrder>(
  "order/createOrder",
  async (params, thunkApi) => {
    try {
      const result = await orderService.createNewOrder(
        params,
        i18next.language
      );
      return result;
    } catch (error) {
      const err = error as AxiosError<ErrorHandler | string>;
      return thunkApi.rejectWithValue({
        code: err.response.status,
        error: err?.response?.data,
      });
    }
  }
);

// eslint-disable-next-line import/prefer-default-export
export const getOrder = createAsyncThunk<
  Order,
  { uniqueId: string; email: string }
>("order/getOrder", async (params, thunkApi) => {
  try {
    const result = await orderService.getOrder(params.uniqueId, params.email);
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});
