import { MultiDaysTimeSlot } from "../shared/types";
import api from "../shared/api/homesampling";
import moment from "moment";

export default class PartnerService {
  private path = "/partner-branches";

  async getAvailableTimeSlot(
    productId: string,
    startDate: Date,
    appointmentId?: string
  ): Promise<MultiDaysTimeSlot[]> {
    const result = await api.get<MultiDaysTimeSlot[]>(
      `${this.path}/homesamepling/timeslots`,
      {
        params: {
          productId,
          startDate,
          dateNow: moment().utcOffset(0, true).toDate(),
          limit: 3,
          appointmentId,
        },
      }
    );
    return result.data;
  }
}
