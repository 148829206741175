export type BaseEntity = {
  _id?: string
  createdAt?: Date
  updatedAt?: Date
  validFrom?: Date
  validTo?: Date
  ver?: number
}

export enum ApiStatus {
  idle,
  pending,
  fulfilled,
  rejected,
}

export type Translation = {
  en: string
  de: string
  fr: string
  it: string
}

export type DateRange = {
  from: Date
  to: Date
}

export enum UserRole {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  DOCTOR = 'doctor',
  CRM_ADMIN = 'crm_admin',
  HOSA_CRM = 'HOSA_CRM',
  DATA_EXPORT = 'data_export',
}

export enum Application {
  RIKAI = 'rikai',
  HOMESAMPLING = 'homesampling',
}

export type Code = {
  _id: string
  type: string
  key: string
  value: Translation
}

export type User = {
  _id: string
  uuid: string
  doctorTitle?: string
  roles: UserRole[]
  firstName: string
  lastName: string
  phoneNo: string
  sex?: string
  birthDate?: Date
  streetAddress?: string
  city?: string
  zipCode: string
  email?: string
  phoneNoVerified?: boolean
  emailVerified?: boolean
  photoAvatarUrl?: string
  preferredLanguage?: string
  mostRecentOrderStatus?: OrderStatus | string
  lastOpenedAt?: Date
  allOrderNumbers?: string[]
  locked?: boolean
  deleted?: boolean
  bloodGroup?: BloodGroupResult
  seenResearchApproval?: boolean
  researchApproval?: boolean
  passport?: string
  country?: string
}

export type BloodDrawMethod = {
  key: string
  default: boolean
  name: Translation
}

export type Product = {
  _id: string
  key: string
  name: Translation
  description: Translation
  imageURL: string
  price: number
  active: boolean
  productGroupId: string
  productGroup?: ProductGroup
  addons?: AddOnProduct[]
  totalPartnerBranches: number
  id?: string
  steps?: Translation[]
  customerId?: string
  medicalName?: Translation
  values?: string[] | Translation[]
  months?: number
  orderId?: string
  order?: Order
  bloodDrawMethod?: BloodDrawMethod[]
  details?: Translation
}

export type ProductGroup = {
  _id: string
  name: Translation
  key: string
  description: Translation
  daysResult: number
  startingPrice: number
  currency: string
  active: boolean
  products: Product[]
}

export type AddOnProduct = {
  _id: string
  name: Translation
  description: Translation
  bloodMeasureIds: string[]
  price: number
  oneTimeFlag: boolean
  active: boolean
  details?: Translation
  key?: string
  tags?: string[]
}

export type AssignedProductId = {
  application: Application
  channel?: Channel
  id: string
}

export type PartnerBranch = {
  _id: string
  name: string
  key: string
  address: string
  country: string
  location: Location
  openingHours: OpeningHours
  operatingHours: OpeningHours
  active: boolean
  maxAppointmentsPerTimeSlot: number
  productIds: AssignedProductId[]
  products?: Product[]
  productGroups?: ProductGroup[]
  temporaryClose?: DateRange[]
  timeSlotDuration: number
  streetName?: string
  streetNumber?: string
  zipCode?: string
  city?: string
  addressDescription?: string
  orders?: Order[]
  permanentlyClose?: DateRange
  additionalEmailAddress?: string
  allowDataSharing?: boolean
  allowAutoDataSharing?: boolean
  disclaimer?: string
  logo?: string
  phoneNo?: string
  application?: Application
}

export type OpeningHours = {
  weekdayText: string[]
  periods: Array<Record<string, unknown>>
}

export type Location = {
  type: string
  coordinates: number[]
}

export type RangeValue = {
  min?: number
  max?: number
}

export enum OrderFlowNo {
  WITH_ORDER = 1,
  AFTER_ORDER = 2,
}

export type RescheduleFee = {
  _id: string
  key: string
  description: Translation
  price: number
}

export type OrderTransaction = {
  rikaiTransactionNo: string
  addons?: AddOnProduct[]
  rescheduleFee?: RescheduleFee
  appointment?: {
    scheduleFrom: Date
    scheduleTo: Date
  }
  timeStamp: Date
  flowNo: OrderFlowNo
  price: number
  voucher?: {
    code: string
    discount: number
  }
  payment?: {
    transactionId?: string
    status?: string
    settledAt?: Date
    paymentMethod?: string
    history?: Array<Record<string, unknown>>
    currency?: string
    card?: {
      masked?: string
      info?: {
        brand?: string
      }
    }
  }
  cancellationReasons?: string
}

export enum Channel {
  CUSTOMER = 'direct',
  PARTNER_SALES = 'partner',
}

export type Address = {
  streetAddress?: string
  city?: string
  zipCode?: string
}

export type Order = {
  _id: string
  customerId: string
  productId: string
  orderNo: string
  shortCode: string
  productPrice: number
  status: OrderStatus
  transactions?: Array<OrderTransaction>
  preparationSurveyId: string
  allowShareData?: boolean
  dateAllowShareData?: Date
  customer?: User
  product?: Product
  productGroup?: ProductGroup
  partnerBranch?: PartnerBranch
  appointment?: Appointment
  preparationSurvey?: CustomerSurvey
  createdAt?: Date
  testResult?: TestResult
  lastUpdatedTransactionNo?: string
  addOnsOrder?: Partial<AddOnProduct[]>
  addOns?: AddOnProduct[]
  supportUUID?: string
  support?: Support
  sampleTakingTime?: Date
  supportRequestedCount?: number
  registrationDate?: Date
  isSpecialDataSharingOrder?: boolean
  channel?: Channel
  seenTermsAndConditions?: boolean
  isFollowUp?: boolean
  deliveryAddress?: Address
  invoiceAddress?: Address
  mainOrderId?: string
  mainResultId?: string
  deliveryDate?: Date
  months?: number
  bloodDrawMethod?: string
}

export enum OrderStatus {
  PAID,
  CONFIRMED,
  ANALYZING,
  RESULT_RECEIEVED,
  COMPLETED,
  CANCELLED,
  READY_FOR_APPROVAL,
}

export type Appointment = {
  _id: string
  orderId: string
  partnerBranchId: string
  scheduleFrom: Date
  scheduleTo: Date
  status?: number
  order?: Order
  product?: Product
  productGroup?: ProductGroup
  customer?: User
  partnerBranch?: PartnerBranch
  customerSurvey?: CustomerSurvey
  onlineAppointmentLink?: string
  application: Application
  createdAt?: Date
}

export type TimeSlot = {
  from: Date
  to: Date
  appointments: Appointment[]
}

export type MultiDaysTimeSlot = {
  date: Date
  timeSlots: TimeSlot[]
}

export enum MeasureLevel {
  OUTSTANDING = 'outstanding',
  GOOD = 'good',
  BELOW_AVERAGE = 'belowAverage',
  BAD = 'bad',
  UNJUDGEABLE = 'unjudgeable',
}

export type MeasureValueRange = {
  sex?: string
  age?: RangeValue
  values: {
    good: RangeValue
    normal?: RangeValue
    caution?: RangeValue
  }
}

export type BloodMeasure = {
  _id: string
  key: string
  name: Translation
  description: Translation
  unit: string
  ranges: MeasureValueRange[]
  productGroupIds: string[]
  categoryId: string
}

export type ResultCategory = {
  _id: string
  key: string
  name: Translation
  summaryText: Array<{
    filters: Array<{
      healthMeasureId: string
      level: MeasureLevel
    }>
    title: string
    notes: string
    text: Translation
  }>
  selfComparisonDescriptions: Translation[]
  healthMeasureIds: string[]
  productGroupIds: string[]
  productIds: string[]
}

export type HealthMeasure = {
  _id: string
  key: string
  name: Translation
  icon: string
  description: Translation
  bloodMeasureIds: string[]
  surveyQuestionIds: string[]
}

export type ResultRecommendation = {
  _id: string
  key: string
  name: Translation
  description: Translation
  icon: string
  bgColor: string
  titleColor: string
  descriptionColor: string
  active: boolean
  link: {
    text: Translation
    url: Translation
  }
}

export type InternalNote = {
  _id?: string
  userId: string
  note: string
  timestamp: Date
  user?: User
}

export type TestResult = {
  _id: string
  orderId: string
  customerId: string
  testDate: Date
  resultCategories: Array<{
    resultCategoryId: string
    level: MeasureLevel
    resultCategorySummary: Translation
    selfComparisonDescriptions: Translation
  }>
  releaseDate?: Date
  healthMeasuresResult: Array<{
    healthMeasureId: string
    level: MeasureLevel
  }>
  bloodMeasuresResult: BloodMeasuresResult[]
  categories?: ResultCategory[]
  healthMeasures?: HealthMeasure[]
  bloodMeasures?: BloodMeasure[]
  bloodMeasureCategories?: Code[]
  resultCategorySummary: Translation
  selfComparisonDescriptions: Translation
  recommendations?: ResultRecommendation[]
  followUpRecommendations?: FollowUpRecommendation[]
  createdAt: Date
  order?: Order
  patient?: User
  verifiedByDoctorText?: string
  hl7OrderId?: string
  pdfPath?: string
  pdfData?: string
  testResultLanguage: string
  internalNotes?: InternalNote[]
  bloodGroup?: BloodGroupResult
  releasedBy?: string
  testResultSmsId?: string
  customerSurvey?: CustomerSurvey
  createdBy?: string
}

export enum CriticalLimitType {
  UPPER = 'Upper',
  LOWER = 'Lower',
}

export type CriticalLimit = {
  name: Translation
  description: Translation
  bloodMeasureName: string
  value: number | string
  limitType: CriticalLimitType
}

export type BloodMeasuresResult = {
  bloodMeasureId: string
  level: MeasureLevel
  value: number
  valueStr: string
  unit: string
  range: { min: number; max: number }
  referenceStr: string
  criticalLimit?: CriticalLimit
  releaseDate?: Date
  testDate?: Date
  bloodMeasureKey?: string
}

export enum SurveyStatus {
  PENDING,
  IN_PROGRESS,
  COMPLETED,
}

export enum SurveyAnswerType {
  DATE = 'date',
  MONTH = 'month',
  SINGLE_VALUE = 'single_value',
  MULTIPLE_VALUES = 'multiple_values',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  YEAR = 'year',
}

export type SurveyAnswerValue = string | number | Date | boolean
export type SurveyAdditionalAnswerValue = { key: string; value: SurveyAnswerValue }[]

export type SurveyDefinition = {
  key: string
  name: Translation
  description: Translation
  pages: Array<{
    question?: string
    questionGroup?: string
  }>
}

export type SurveyQuestionCategory = {
  _id: string
  key: string
  name: Translation
}

export type SurveyQuestion = {
  horizontal?: boolean
  _id: string
  key: string
  active?: boolean
  questionCategory: SurveyQuestionCategory
  title?: Translation
  detail?: Translation
  question: Translation
  answerType: SurveyAnswerType
  answerOptions: Array<{
    text: Translation
    value: SurveyAnswerValue
  }>
  additionalAnswer?: Array<{
    answerType?: SurveyAnswerType
    displayOn: SurveyAnswerValue
    answerPlaceHolder?: Translation
    answerUnit?: Translation
    minValue?: number
    maxValue?: number
    question?: string
    title?: string
    key?: string
  }>

  productGroupIds: string
  answerDefaultValue?: SurveyAnswerValue
  answerPlaceHolder?: Translation
  answerUnit?: string
  minValue?: number
  maxValue?: number
  additionalInfo?: Translation
  reasonForQuestion?: Translation
  rules?: Record<string, Record<string, unknown>>
  requiresPrevious?: boolean
  followUpQuestionKeys?: string[]
}

export enum BloodGroupValue {
  NEGATIVE = '-',
  POSITIVE = '+',
}

export type BloodGroupResult = {
  group: string
  rh: BloodGroupValue
  kellAntigen: BloodGroupValue
  rhp: string
}

export type CustomerSurvey = {
  _id: string
  customerId: string
  surveyDefinitionId: string
  answers: Array<{
    questionId: string
    answerValue: SurveyAnswerValue
    question?: SurveyQuestion
    questionKey?: string
    questionGroup?: string
    additionalAnswer?: SurveyAdditionalAnswerValue
  }>
  status: SurveyStatus
  customer?: User
  surveyDefinition?: SurveyDefinition
  preparationSurveyForOrder?: Order
  questions?: SurveyQuestion[]
  product: Product
  productGroup: ProductGroup
  createdAt?: Date
}

export type FollowUpRecommendation = Product & {
  followUpFirstReminderSmsId?: string
  followUpSecondReminderSmsId?: string
  orderId?: string
  order?: Order
}

export type AuthToken = {
  accessToken: string
  idToken: string
  expiresIn: string
}

export type Support = {
  name: string
  key: string
  uuid: string
  description: Translation
  isDefault: boolean
  link?: string
  logoPath?: string
  mailTo: string
  active?: boolean
} & BaseEntity

export type ErrorHandler = {
  statusCode: number
  error: string
  message: string
  field?: string
}
