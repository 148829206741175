import adminApi from "./shared/api/admin";
import authenticationApi from "./shared/api/auth";
import crmApi from "./shared/api/crm";
import customerApi from "./shared/api/customer";
import homeSamplingApi from "./shared/api/homesampling";
import voucherApi from "./shared/api/voucher";
import { createErrorInterceptor } from "./shared/interceptors/error.interceptor";

import { createLanguageInterceptor } from "./shared/interceptors/language.interceptor";
import { getAuthToken } from "./shared/lib/auth";
import { identity } from "./shared/lib/identity";
import AuthPaths from "./shared/modules/auth/AuthPaths";
import { AxiosRequestConfig } from "axios";

import i18next from "i18next";

// TODO LEFT AS IT WAS BEFORE BECAUSE REDUCERS ARE SAVING TOKEN ON STORAGE AND USES DIFFERENT STORE FROM OTHER APPS
export function authorizationInterceptor(
  request: AxiosRequestConfig
): AxiosRequestConfig {
  const token = getAuthToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token.idToken}`;
  }

  return request;
}

// TODO LEFT AS IT WAS BEFORE USES ANOTHER STORE THAT USES DIFFERENT AUTHENTICATION
function redirect(url: string): void {
  // TODO CHANGE TO REACT ROUTER TO AVOID PAGE RELOAD AND REFETCH APPLICATION
  window.location.href = url;
}

const errorInterceptor = createErrorInterceptor({
  redirectPath: AuthPaths.AUTH_LOGIN,
  redirect,
});

export function setupInterceptors(): void {
  const languageInterceptor = createLanguageInterceptor(() => i18next.language);

  const APIS = [
    authenticationApi,
    adminApi,
    customerApi,
    homeSamplingApi,
    crmApi,
    voucherApi,
  ];

  APIS.forEach((api) => {
    api.interceptors.request.use(authorizationInterceptor);
    api.interceptors.request.use(languageInterceptor);
    api.interceptors.response.use(identity, errorInterceptor);
  });
}
