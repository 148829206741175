import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setAuthToken } from "../../shared/lib/auth";
import { ApiStatus } from "../../shared/types";
import config from "../../config";
import { IErrorComponent } from "../../types";
import { EmailVerificationResponse, User, UserState } from "../../types/user";
import {
  updateCustomer,
  updateLanguage,
  verifyEmail,
  verifyLink,
} from "./UserThunk";

const initialState: UserState = {
  currentUser: {
    apiStatus: ApiStatus.idle,
    data: {
      _id: sessionStorage.getItem("userId") || "",
      email: sessionStorage.getItem("email") || "",
      firstName: "",
      lastName: "",
      sex: "",
      birthDate: null,
      streetAddress: "",
      zipCode: "",
      city: "",
      preferredLanguage:
        localStorage.getItem("i18nextLng") || config.defaultLanguage,
    },
  },
  isVerified: {
    apiStatus: ApiStatus.idle,
    data: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveCurrentUser: (state: UserState, action: PayloadAction<User>) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: {
            email: state?.currentUser?.data?.email,
            ...action.payload,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyEmail.pending, (state: UserState) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      verifyEmail.fulfilled,
      (state: UserState, action: PayloadAction<string>) => {
        return {
          ...state,
          currentUser: {
            apiStatus: ApiStatus.fulfilled,
            data: {
              ...state.currentUser.data,
              email: action.payload,
            },
          },
        };
      }
    );
    builder.addCase(verifyEmail.rejected, (state: UserState) => {
      return {
        ...state,
        currentUser: {
          apiStatus: ApiStatus.rejected,
          data: initialState.currentUser.data,
        },
      };
    });
    builder.addCase(verifyLink.pending, (state: UserState) => {
      return {
        ...state,
        isVerified: {
          ...state.isVerified,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      verifyLink.fulfilled,
      (state: UserState, action: PayloadAction<EmailVerificationResponse>) => {
        setAuthToken(action.payload.authToken);
        sessionStorage.setItem("userId", action.payload.user._id);
        sessionStorage.setItem("email", action.payload.email);
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            data: {
              ...state.currentUser.data,
              _id: action.payload.user._id,
              email: action.payload.email,
              ...(action.payload.order?.customer && {
                ...action.payload.order?.customer,
              }),
            },
          },
          isVerified: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload.isVerified,
          },
        };
      }
    );
    builder.addCase(
      verifyLink.rejected,
      (state: UserState, action: PayloadAction<IErrorComponent>) => {
        return {
          ...state,
          isVerified: {
            apiStatus: ApiStatus.rejected,
            data: initialState.isVerified.data,
            error: action.payload,
          },
        };
      }
    );
    builder.addCase(updateCustomer.pending, (state: UserState) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      updateCustomer.fulfilled,
      (state: UserState, action: PayloadAction<User>) => {
        return {
          ...state,
          currentUser: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(updateCustomer.rejected, (state: UserState) => {
      return {
        ...state,
        currentUser: {
          apiStatus: ApiStatus.rejected,
          data: initialState.currentUser.data,
        },
      };
    });
    builder.addCase(
      updateLanguage.fulfilled,
      (
        state: UserState,
        action: PayloadAction<{ preferredLanguage: string }>
      ) => {
        const { currentUser } = state;
        const newCurrentUserData = {
          ...currentUser.data,
          preferredLanguage: action.payload.preferredLanguage,
        };
        return {
          ...state,
          currentUser: {
            apiStatus: ApiStatus.fulfilled,
            data: newCurrentUserData,
          },
        };
      }
    );
  },
});

export const { saveCurrentUser } = userSlice.actions;

export default userSlice.reducer;
