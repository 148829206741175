import React, { FC } from "react";
import { ReactComponent as RikaiSpinner } from "../../assets/spinner.svg";
import "../LoadingSpinner/spinner.css";
import { useDelay } from "../../react-utils/hooks/use-delay";
import { LoadingSpinnerType } from "../LoadingSpinner";

export interface DelayLoaderProps {
  type?: LoadingSpinnerType;
  delay?: number;
}

export const DelayedLoader: FC<DelayLoaderProps> = ({
  type = "circle",
  delay = 0,
}) => {
  const show = useDelay(delay);

  const loader =
    type === "rikai" ? (
      <RikaiSpinner className="rikaiLoader" />
    ) : (
      <div className="loader" />
    );

  return show && <div className="spinnerCenter">{loader}</div>;
};
