/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatus, Order } from "../../shared/types";
import { IErrorComponent } from "../../types";
import { OrderState } from "../../types/order";
import { EmailVerificationResponse } from "../../types/user";
import { setCurrentAppointment } from "../appointment/AppointmentReducer";
import { verifyLink } from "../user/UserThunk";
import { createOrder, getOrder } from "./OrderThunk";

const initialState: OrderState = {
  currentOrder: { apiStatus: ApiStatus.idle },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCurrentOrder: (state: OrderState, action: PayloadAction<Order>) => {
      return {
        ...state,
        currentOrder: { apiStatus: ApiStatus.fulfilled, data: action.payload },
      };
    },
    updateCurrentOrder: (
      state: OrderState,
      action: PayloadAction<Partial<Order>>
    ) => {
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          data: {
            ...state.currentOrder.data,
            ...action.payload,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state: OrderState) => {
      state.currentOrder.apiStatus = ApiStatus.pending;
    });
    builder.addCase(
      createOrder.fulfilled,
      (state: OrderState, action: PayloadAction<Order>) => {
        state.currentOrder = {
          apiStatus: ApiStatus.fulfilled,
          data: action.payload,
        };
        if (action.payload.appointment) {
          setCurrentAppointment(action.payload.appointment);
        }
      }
    );
    builder.addCase(createOrder.rejected, (state: OrderState, action) => {
      state.currentOrder = {
        apiStatus: ApiStatus.rejected,
        error: action.payload as IErrorComponent,
      };
    });
    builder.addCase(
      verifyLink.fulfilled,
      (state: OrderState, action: PayloadAction<EmailVerificationResponse>) => {
        return {
          ...state,
          currentOrder: {
            ...state.currentOrder,
            data: action.payload.order,
          },
        };
      }
    );
    builder.addCase(getOrder.pending, (state: OrderState) => {
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          apiStatus: ApiStatus.pending,
        },
      };
    });
    builder.addCase(
      getOrder.fulfilled,
      (state: OrderState, action: PayloadAction<Order>) => {
        return {
          ...state,
          currentOrder: {
            apiStatus: ApiStatus.fulfilled,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getOrder.rejected, (state: OrderState) => {
      return {
        ...state,
        currentOrder: {
          apiStatus: ApiStatus.rejected,
          data: null,
        },
      };
    });
  },
});

export const { setCurrentOrder, updateCurrentOrder } = orderSlice.actions;

export default orderSlice.reducer;
