import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorHandler, SurveyAnswerValue } from "../../shared/types";
import { AxiosError } from "axios";
import SurveyService from "../../services/SurveyService";
import {
  HomeSamplingCustomerSurvey,
  HomeSamplingSurveyQuestion,
} from "../../types/survey";

const surveyService = new SurveyService();

export const getSurvey = createAsyncThunk<
  HomeSamplingCustomerSurvey,
  { id: string }
>("survey/getSurvey", async (params, thunkApi) => {
  try {
    const result = await surveyService.getSurveyById(params.id);
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});

export const getSurveyQuestion = createAsyncThunk<
  HomeSamplingSurveyQuestion,
  { id: string }
>("survey/getSurveyQuestion", async (params, thunkApi) => {
  try {
    const result = surveyService.getSurveyQuestion(params.id);
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});

export const saveSurvey = createAsyncThunk<
  HomeSamplingCustomerSurvey,
  {
    id: string;
    answers: Array<{
      questionId: string;
      answerValue: SurveyAnswerValue;
      questionKey?: string;
      additionalAnswer?: SurveyAnswerValue;
    }>;
    uniqueId?: string;
  }
>("survey/saveSurvey", async (params, thunkApi) => {
  try {
    const result = surveyService.saveSurvey(
      params.id,
      params.answers,
      params.uniqueId
    );
    return result;
  } catch (error) {
    const err = error as AxiosError<ErrorHandler | string>;
    return thunkApi.rejectWithValue({
      code: err.response.status,
      error: err?.response?.data,
    });
  }
});
