/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatus, MultiDaysTimeSlot } from "../../shared/types";
// import config from '../../config'

import { PartnerState } from "../../types/partner";
import { IErrorComponent } from "../../types/share";
import { fetchTimeslots } from "./PartnerThunk";

const initialState: PartnerState = {
  iEntityCurrentPartner: { apiStatus: ApiStatus.idle },
  iEntityTimeslotes: { apiStatus: ApiStatus.idle, data: [] },
};

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    addMultipleTimeslots: (
      state: PartnerState,
      action: PayloadAction<MultiDaysTimeSlot[]>
    ) => {
      state.iEntityTimeslotes?.data.push(...action.payload);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTimeslots.pending, (state: PartnerState) => {
      state.iEntityTimeslotes = { apiStatus: ApiStatus.pending, data: [] };
    });
    builder.addCase(
      fetchTimeslots.fulfilled,
      (state, action: PayloadAction<MultiDaysTimeSlot[]>) => {
        state.iEntityTimeslotes = {
          apiStatus: ApiStatus.fulfilled,
          data: action.payload,
        };
      }
    );
    builder.addCase(fetchTimeslots.rejected, (state, action) => {
      state.iEntityTimeslotes = {
        apiStatus: ApiStatus.rejected,
        error: action.payload as IErrorComponent,
      };
    });
  },
});

export const { addMultipleTimeslots } = partnerSlice.actions;

export default partnerSlice.reducer;
