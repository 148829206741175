import React from 'react'
import AppRoutes from './routes'
import { setupInterceptors } from './interceptors.config'

setupInterceptors()

const App: React.FunctionComponent = () => {
  return <AppRoutes />
}

export default App
