import { useEffect, useState } from 'react'

/**
 * Delays the transition of state from false to true for a specified amount of time
 * @param delay time
 *
 * @example
 *
 * export const DelayedContent = () => {
 *  const show = useDelay(800)
 *  // show content after loading is in screen for 800
 *  return show ? <p>Content</p> : <AnimatedLoader />
 * }
 *
 */
export function useDelay(delay: number = 0): boolean {
  const [state, setState] = useState(delay <= 0)

  useEffect(() => {
    if (delay > 0) {
      const timerId = setTimeout(() => setState(true), delay)

      return () => clearTimeout(timerId)
    }
  }, [delay])

  return state
}
