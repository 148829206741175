import api from "../shared/api/customer";
import { Product } from "../shared/types";

export default class ProductService {
  private path = "/products";

  async getProductById(id: string): Promise<Product> {
    const response = await api.get(`${this.path}/${id}`);
    return response.data;
  }
}
