import { DelayedLoader } from "../shared/components/DelayedLoader/DelayedLoader";
import ScrollToTop from "../shared/components/ScrollToTop";
import { delayedImport } from "../shared/lib/delayed-import";
import { FunctionComponent, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ROUTE_CONFIG } from "../route.config";

const PersonalDetails = lazy(() =>
  delayedImport("pages/PersonalDetails", ROUTE_CONFIG)
);
const Address = lazy(() => delayedImport("pages/Address", ROUTE_CONFIG));
const AfterVerified = lazy(() =>
  delayedImport("pages/AfterVerified", ROUTE_CONFIG)
);
const Verify = lazy(() => delayedImport("pages/Verify", ROUTE_CONFIG));
const EmailVerification = lazy(() =>
  delayedImport("pages/EmailVerification", ROUTE_CONFIG)
);
const VerifyLink = lazy(() => delayedImport("pages/VerifyLink", ROUTE_CONFIG));
const MedicalQuestionsSurvey = lazy(() =>
  delayedImport("pages/MedicalQuestionsSurvey", ROUTE_CONFIG)
);

const AppointmentSelection = lazy(() =>
  delayedImport("pages/Appointment", ROUTE_CONFIG)
);
const Confirmation = lazy(() =>
  delayedImport("pages/Confirmation", ROUTE_CONFIG)
);

export enum Paths {
  HOME = "/",
  VERIFY = "/verify",
  VERIFY_LINK = "/verify/:token",
  AFTER_VERIFIED = "/after-verified",
  PERSONAL_DETAILS = "/personal-details",
  ADDRESS = "/address",
  APPOINTMENT_SELECTION = "/appointment-selection",
  CONFIRMATION = "/confirmation",
  MEDICAL_QUESTIONS_SURVEY = "/surveys/:id/pages/:page",
}

const AppRoutes: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense
        fallback={
          <DelayedLoader delay={ROUTE_CONFIG.fallbackTime} />
        }
      >
        <Switch>
          <Route
            exact
            path={Paths.PERSONAL_DETAILS}
            component={PersonalDetails}
          />
          <Route exact path={Paths.ADDRESS} component={Address} />
          <Route
            exact
            path={Paths.MEDICAL_QUESTIONS_SURVEY}
            component={MedicalQuestionsSurvey}
          />
          <Route exact path={Paths.AFTER_VERIFIED} component={AfterVerified} />
          <Route exact path={Paths.VERIFY_LINK} component={VerifyLink} />
          <Route exact path={Paths.VERIFY} component={Verify} />
          <Route
            path={Paths.APPOINTMENT_SELECTION}
            component={AppointmentSelection}
          />
          <Route path={Paths.CONFIRMATION} component={Confirmation} />
          <Route path={Paths.HOME} component={EmailVerification} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
