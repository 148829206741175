import api from "../shared/api/homesampling";
import { Application, Appointment } from "../shared/types";

export default class AppointmentService {
  private path = "/appointments";

  async updateAppointment(params: {
    _id: string;
    orderId: string;
    partnerBranchId: string;
    scheduleFrom: Date;
    scheduleTo: Date;
    userId: string;
    crmAdmin?: boolean;
  }): Promise<Appointment> {
    const response = await api.put<Appointment>(
      `${this.path}/${params._id}/reschedule`,
      params,
      {
        params: { application: Application.HOMESAMPLING },
      }
    );
    return response.data;
  }
}
