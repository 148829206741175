import * as dotenv from "dotenv";
import baseConfig from "./shared/config";

dotenv.config({ path: ".env" });

const config = {
  ...baseConfig,

  appName: "Homesampling",
  showTimeSlots: 12,
  homesamplingActivateUrl: "https://www.homesampling.ch/activate",
  country: "Switzerland",
};

export default config;
